import { Component, OnInit } from "@angular/core";
//import { UserIdleService } from 'angular-user-idle';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
  ActivatedRoute,
} from "@angular/router";
import { Observable } from "rxjs";
import { Idle, DEFAULT_INTERRUPTSOURCES } from "@ng-idle/core";
import { Keepalive } from "@ng-idle/keepalive";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AppService } from "./service/app.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  title = "nobleui-angular";
  idleState = "Not started.";
  timedOut = false;
  lastPing?: Date = null;
  //title = 'angular-idle-timeout';

  constructor(
    private appService: AppService,
    private idle: Idle,
    private keepalive: Keepalive,
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute
  ) {
    // sets an idle timeout of 5 seconds, for testing purposes.
    idle.setIdle(300);
    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    idle.setTimeout(450);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => {
      this.idleState = "No longer idle.";
      console.log(this.idleState);
      this.reset();
    });

    idle.onTimeout.subscribe(() => {
      this.idleState = "Timed out!";
      this.timedOut = true;
      console.log(this.idleState);
      //this.router.navigate(['/']);
      this.router.navigate(["auth/login"]);
    });

    idle.onIdleStart.subscribe(() => {
      this.idleState = "You've gone idle!";
      //alert("You have been idle for a minute.You will be signed out in 30 seconds");
      console.log(this.idleState);
    });

    idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = "You will time out in " + countdown + " seconds!";
      console.log(this.idleState);
    });

    // sets the ping interval to 15 seconds
    keepalive.interval(15);

    keepalive.onPing.subscribe(() => (this.lastPing = new Date()));

    this.appService.getUserLoggedIn().subscribe((userLoggedIn) => {
      if (userLoggedIn) {
        idle.watch();
        this.timedOut = false;
      } else {
        idle.stop();
      }
    });

    this.reset();
  }
  reset() {
    this.idle.watch();
    this.idleState = "Started.";
    this.timedOut = false;
  }
  openBasicModal(content) {
    this.modalService
      .open(content, {})
      .result.then((result) => {
        //this.basicModalCloseResult = "Modal closed" + result
      })
      .catch((res) => {});
  }

  ngOnInit(): void {}
}
