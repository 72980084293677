// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  /*confirmaccounturl:'https://bainesaccountopeningapi.herokuapp.com/GetCustomerByAccountNo',
  customerdetailsurl:'https://bainesaccountopeningapi.herokuapp.com/CustomerDetails',
  fundaccounturl:'https://bainesaccountopeningapi.herokuapp.com/FundAccount',
  transhistoryurl:'https://bainesaccountopeningapi.herokuapp.com/TransHistory',
  scheduleurl:'https://bainesaccountopeningapi.herokuapp.com/loanschedule',
  loandetailsurl:'https://bainesaccountopeningapi.herokuapp.com/loandetails',
  loadcardsurl:'https://bainesaccountopeningapi.herokuapp.com/loadcards',
  verifyandlinkcardurl:'https://bainesaccountopeningapi.herokuapp.com/verifyPaystackTrans'*/

  /*
  confirmaccounturl: "https://api.bainescredit.com/GetCustomerByAccountNumber",
  verifyotpurl: "https://api.bainescredit.com/VerifyOTPToken",
  confirmaccountnootpurl:
    "https://api.bainescredit.com/GetCustomerByAccountNumberWithNoOTP",
  customerdetailsurl: "https://api.bainescredit.com/GetCustomerDetails",
  transhistoryurl: "https://api.bainescredit.com/TransactionsHistory",
  fundaccounturl: "https://api.bainescredit.com/AccountFunding",
  loadallloansurl: "https://api.bainescredit.com/GetLoanDetailsByCustomerID",
  loandetailsurl: "https://api.bainescredit.com/GetLoanDetails",
  scheduleurl: "https://api.bainescredit.com/GetLoanSchedule",
  loadcardsurl: "https://api.bainescredit.com/LoadLinkedCards",
  verifyandlinkcardurl:
    "https://api.bainescredit.com/VerifyPaystackTransaction",

  */

  confirmaccounturl: "https://dev.ekofreshfoods.com/GetCustomerByAccountNumber",
  verifyotpurl: "https://dev.ekofreshfoods.com/VerifyOTPToken",
  confirmaccountnootpurl:
    "https://dev.ekofreshfoods.com/GetCustomerByAccountNumberWithNoOTP",
  customerdetailsurl: "https://dev.ekofreshfoods.com/GetCustomerDetails",
  transhistoryurl: "https://dev.ekofreshfoods.com/TransactionsHistory",
  fundaccounturl: "https://dev.ekofreshfoods.com/AccountFunding",
  loadallloansurl: "https://dev.ekofreshfoods.com/GetLoanDetailsByCustomerID",
  loandetailsurl: "https://dev.ekofreshfoods.com/GetLoanDetails",
  scheduleurl: "https://dev.ekofreshfoods.com/GetLoanSchedule",
  loadcardsurl: "https://dev.ekofreshfoods.com/LoadLinkedCards",
  verifyandlinkcardurl:
    "https://dev.ekofreshfoods.com/VerifyPaystackTransaction",

  // confirmaccounturl: "http://127.0.0.1:1379/GetCustomerByAccountNumber",
  // verifyotpurl: "http://127.0.0.1:1379/VerifyOTPToken",
  // confirmaccountnootpurl:
  //   "http://127.0.0.1:1379/GetCustomerByAccountNumberWithNoOTP",
  // customerdetailsurl: "http://127.0.0.1:1379/GetCustomerDetails",
  // transhistoryurl: "http://127.0.0.1:1379/TransactionsHistory",
  // fundaccounturl: "http://127.0.0.1:1379/AccountFunding",
  // loadallloansurl: "http://127.0.0.1:1379/GetLoanDetailsByCustomerID",
  // loandetailsurl: "http://127.0.0.1:1379/GetLoanDetails",
  // scheduleurl: "http://127.0.0.1:1379/GetLoanSchedule",
  // loadcardsurl: "http://127.0.0.1:1379/LoadLinkedCards",
  // verifyandlinkcardurl: "http://127.0.0.1:1379/VerifyPaystackTransaction",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
