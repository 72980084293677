import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { AppRoutingModule } from "./app-routing.module";

import { LayoutModule } from "./views/layout/layout.module";
import { AuthGuard } from "./core/guard/auth.guard";

import { AppComponent } from "./app.component";
import { ErrorPageComponent } from "./views/pages/error-page/error-page.component";

import { HIGHLIGHT_OPTIONS } from "ngx-highlightjs";
import { FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { PaystackComponent } from "./paystack/paystack.component";
import { Angular4PaystackModule } from "angular4-paystack";
import { PaystackOptions } from "angular4-paystack";
import { HashLocationStrategy, LocationStrategy } from "@angular/common";
//import { UserIdleModule } from 'angular-user-idle';
import { NgIdleKeepaliveModule } from "@ng-idle/keepalive"; // this includes the core NgIdleModule but includes keepalive providers for easy wireup
import { MomentModule } from "angular2-moment"; // optional, provides moment-style pipes for date formatting

/**provider */
@NgModule({
  declarations: [AppComponent, ErrorPageComponent, PaystackComponent],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LayoutModule,
    FormsModule,
    Angular4PaystackModule.forRoot(
      "pk_test_c8338f075e5ce8b8c4a93b6d3a202017421c3162"
    ),
    // Angular4PaystackModule.forRoot('pk_live_4950d80be98dd233376353a5ea8c2b080fa8993d'),
    // Angular4PaystackModule.forRoot(
    //   "pk_live_4950d80be98dd233376353a5ea8c2b080fa8993d"
    // ),
    //UserIdleModule.forRoot({idle: 60, timeout: 60, ping: 60})
    NgIdleKeepaliveModule.forRoot(),
    MomentModule,
  ],
  providers: [
    AuthGuard,
    {
      provide: HIGHLIGHT_OPTIONS, // https://www.npmjs.com/package/ngx-highlightjs
      useValue: {
        coreLibraryLoader: () => import("highlight.js/lib/core"),
        languages: {
          xml: () => import("highlight.js/lib/languages/xml"),
          typescript: () => import("highlight.js/lib/languages/typescript"),
          scss: () => import("highlight.js/lib/languages/scss"),
        },
      },
    },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
